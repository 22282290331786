<template>
  <a-page-header
    @back="() => $router.go(-1)"
    title="PROCESAR INDICADORES MASIVAMENTE"
    class="pt-0 px-0"
  >
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Proyecto</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Parámetros de indicador</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex flex-wrap mb-4">
    <fieldset class="border border-solid w-full md:w-1/2 border-gray-300 p-3 px-8 lg:w-auto">
      <legend class="text-sm text-center">Filtros</legend>
      <ParametrosFiltros @fetchParametros="fetchParametros" :currentPage="currentPage" />
    </fieldset>

    <fieldset class="border border-solid w-full border-gray-300 p-3 px-8 md:w-1/2 lg:w-auto">
      <legend class="text-sm text-center">Acciones</legend>

      <button class="text-center" @click="handleAdd">
        <div class="text-3xl text-blue-400">
          <HistoryOutlined />
        </div>
        <p>Procesar</p>
      </button>
    </fieldset>
  </div>

  <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
    <a-table
      :columns="columns"
      :data-source="data"
      size="small"
      :pagination="false"
      rowKey="idindicador"
      :loading="isLoading"
      :rowClassName="(_record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
    >
      <template #rangepages="{ index }">
        <p class="text-center">{{ index + 1 }}</p>
      </template>

      <template #idindicador="{ text: idindicador }">
        <p class="text-center font-bold">{{ idindicador }}</p>
      </template>

      <template #ejecucion="{ text: ejecucion }">
        <div class="text-center">
          <a-tag v-if="ejecucion === '1'" color="success">
            <template #icon>
              <CheckCircleOutlined />
            </template>
            exitoso
          </a-tag>

          <a-tag v-if="ejecucion == '0'" color="processing">
            <template #icon>
              <SyncOutlined :spin="true" />
            </template>
            procesando
          </a-tag>

          <a-tag v-if="ejecucion === '3'" color="error">
            <template #icon>
              <CloseCircleOutlined />
            </template>
            periodo ya procesado
          </a-tag>
        </div>
      </template>
    </a-table>
  </div>

  <!--------------- Modal--------------->
  <PeriodoEvaluacionMasivoAddEdit
    v-if="isVisible"
    :isVisible="isVisible"
    :parametro="parametro"
    @closeModal="closeModal"
    @fetchParametros="fetchParametros"
    @clearSelectedArrays="clearSelectedArrays"
  />
</template>

<script>
import { ref, watch, reactive, onUnmounted, onMounted } from "vue";
import { useStore } from "vuex";
import { columns } from "./utilsIndicadorMasivo";
import ParametrosFiltros from "./IndicadorParametrosFiltros.vue";
import PeriodoEvaluacionMasivoAddEdit from "./IndicadorPeriodoEvaluacionMasivoAddEdit.vue";
import IndicadorMasivoApi from "@/api/indicadores_masivo";
import { useRoute } from "vue-router";
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  HistoryOutlined
} from "@ant-design/icons-vue";
import moment from "moment";

export default {
  components: {
    ParametrosFiltros,
    PeriodoEvaluacionMasivoAddEdit,
    // icons
    SyncOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    HistoryOutlined
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const pageSize = ref(10);
    const totalDocs = ref(null);
    const isVisible = ref(false);
    const intervalId = ref("");
    const data = ref([]);
    const idindicador = ref("");
    const isLoading = ref(false);
    const parametro = ref(null);
    const state = reactive({
      selectedRowKeys: []
    });
    const isDisabled = reactive({
      edit: true,
      deleteAndChange: true
    });

    const currentPage = store.getters["parametros/currentPage"];

    idindicador.value = route.params?.indicador;

    const fetchParametros = () => {
      IndicadorMasivoApi.getAll()
        .then((response) => {
          console.log("fetchParametros", response);
          const temp = response.data.map((ind) => ({
            ...ind,
            fechaproceso_formated: ind.fechaproceso ? moment(ind.fechaproceso).format("L") : ""
          }));
          data.value = temp;
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      fetchParametros();
      getDataFrequently();
    });

    onUnmounted(() => {
      clearInterval(intervalId.value);
    });

    const getDataFrequently = () => {
      intervalId.value = setInterval(() => {
        fetchParametros();
      }, 2500);
    };

    const onChangePage = (newPage) => {
      store.dispatch("parametros/setCurrentPage", newPage);
      fetchParametros();
      clearSelectedArrays();
    };

    watch(state, () => {
      isDisabled.edit = state.selectedRowKeys.length !== 1;
      isDisabled.deleteAndChange = !state.selectedRowKeys.length;
    });

    // ------- mostrar modal -------
    const showModal = () => {
      isVisible.value = true;
    };

    const closeModal = () => {
      isVisible.value = false;
    };

    // ------- acciones -------
    const handleAdd = () => {
      parametro.value = {
        clave: "",
        valor: "",
        idindicador: idindicador.value
      };
      showModal();
    };

    const handleEdit = () => {
      showModal();
    };

    const clearSelectedArrays = () => {
      state.selectedRowKeys = [];
    };

    return {
      isLoading,
      data,
      columns,
      handleAdd,
      handleEdit,
      pageSize,
      currentPage,
      totalDocs,
      isVisible,
      closeModal,
      fetchParametros,
      parametro,
      isDisabled,
      clearSelectedArrays,
      onChangePage
    };
  }
};
</script>

<style></style>
