<template>
  <a-modal
    :visible="true"
    title="Procesar indicadores masivamente"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    class="max-w-sm md:max-w-5xl"
    width="100%"
    okText="Guardar"
    :maskClosable="false"
  >
    <a-form :model="formState" ref="formRef" layout="vertical">
      <a-form-item label="Selecciona los rangos de fechas" name="periodo">
        <a-range-picker v-model:value="formState.fechas" format="DD/MM/YYYY" class="w-full" />
      </a-form-item>
    </a-form>

    <div class="overflow-x-auto w-full bg-white border-2 rounded-lg p-4">
      <a-table
        :data-source="data"
        :row-selection="rowSelection"
        :custom-row="customRow"
        :columns="columnsAddEditMasivo"
        size="small"
        :pagination="false"
        :loading="isLoading"
        rowKey="idindicador"
        :rowClassName="(_record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
      >
        <template #rangepages="{ index }">
          <span> {{ index + 1 }} </span>
        </template>
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import { reactive, onMounted, toRaw, ref, computed } from "vue";
import { notification } from "ant-design-vue";
import IndicadorApi from "@/api/indicador";
import { columnsAddEditMasivo } from "./utilsIndicadorMasivo";
import IndicadorMasivoApi from "@/api/indicadores_masivo";
import moment from "moment";

export default {
  props: {
    parametro: {
      type: Object
    }
  },
  emits: ["closeModal", "fetchParametros", "clearSelectedArrays"],
  setup(props, { emit }) {
    const formRef = ref();
    const isLoading = ref(false);
    const data = ref([]);
    const state = reactive({
      selectedRowKeys: []
    });

    const formState = reactive({
      fechas: ""
    });

    const closeModal = () => {
      emit("closeModal");
      console.log("Hola cerrar modal");
    };

    const fetchIndicadores = () => {
      isLoading.value = true;
      IndicadorApi.getAllIndicadores()
        .then((response) => {
          console.log("IndicadorApi.getAllIndicadores", response);
          const selectedRowKeys = [];

          data.value = response.data;

          response.data.forEach((element) => {
            selectedRowKeys.push(element.idindicador);
          });

          state.selectedRowKeys = selectedRowKeys;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    const rowSelection = computed(() => {
      return {
        selectedRowKeys: state.selectedRowKeys,
        onChange: (selectedRowKeys) => {
          state.selectedRowKeys = selectedRowKeys;
        }
      };
    });

    onMounted(() => {
      fetchIndicadores();
    });

    const customRow = (record) => {
      return {
        onClick: () => {
          selectRow(record);
        }
      };
    };

    const selectRow = (record) => {
      const selectedRowKeys = [...state.selectedRowKeys];
      if (selectedRowKeys.indexOf(record.idusuario) >= 0) {
        selectedRowKeys.splice(selectedRowKeys.indexOf(record.idusuario), 1);
      } else {
        selectedRowKeys.push(record.idusuario);
      }
      state.selectedRowKeys = selectedRowKeys;
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          try {
            const fecha_ini = formState.fechas[0].format("YYYY-MM-DD") + " 00:00:00";
            const fecha_fin =
              formState.fechas[1].format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss");

            var ids = toRaw(state.selectedRowKeys);
            var info = "";

            ids.forEach((element) => {
              info += element + "|";
            });

            info = info.substring(0, info.length - 1);

            IndicadorMasivoApi.addOne(info, fecha_ini, fecha_fin)
              .then(() => {
                notification.success({
                  message: "Operación exitosa",
                  description: "Script ejecutado."
                });
              })
              .catch((err) => console.log(err));

            emit("closeModal");
            emit("fetchParametros");
          } catch (error) {
            notification.error({
              message: "Operación inválida",
              description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    };

    const handleCancel = () => {
      emit("clearSelectedArrays");
      emit("fetchParametros");
      emit("closeModal");
    };

    const onSearch = (algo) => {
      console.log("algo", algo);
    };

    const rules = {};

    const layout = {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18,
        offset: 1
      }
    };
    return {
      handleOk,
      data,
      closeModal,
      handleCancel,
      rowSelection,
      customRow,
      isLoading,
      formState,
      formRef,
      columnsAddEditMasivo,
      layout,
      rules,
      onSearch
    };
  }
};
</script>
