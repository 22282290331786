/**
 * Contiene datos para las tablas y formularios
 */
export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "ID Indicador",
    dataIndex: "idindicador",
    key: "idindicador",
    slots: { customRender: "idindicador" },
    width: 50
  },
  {
    title: "Nombre resumido",
    dataIndex: "indicador",
    key: "indicador",
    width: 330
  },
  {
    title: "Abreviatura",
    dataIndex: "abreviatura",
    key: "abreviatura",
    width: 100,
    ellipsis: true
  },
  {
    title: "Año",
    dataIndex: "anio",
    key: "anio",
    width: 90
  },
  {
    title: "Último procesamiento",
    dataIndex: "fechaproceso_formated",
    key: "fechaproceso_formated",
    width: 100
  },
  {
    title: "Estado",
    dataIndex: "ejecucion",
    key: "ejecucion",
    width: 80,
    slots: { customRender: "ejecucion" }
  }
];

export const columnsAddEditMasivo = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "NOMBRE",
    dataIndex: "denominacion",
    key: "denominacion",
    width: 300,
    ellipsis: true
  },
  {
    title: "Abreviatura",
    dataIndex: "abreviatura",
    key: "abreviatura",
    width: 100,
    ellipsis: true
  },
  {
    title: "Año",
    dataIndex: "anio",
    key: "anio",
    width: 90
  }
];
