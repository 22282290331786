import { post } from "./base";

const API_SERVICE_QUERY = "/api/Service/V1/query";
const API_SERVICE = "/api/Service/V1/script_masivo";

export default {
  getAll: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_indicadores_masivo",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  },

  addOne: (idindicadores, fecha_ini, fecha_fin) => {
    const request = {
      _type: 2,
      _table: "parametro",
      _petitions: [
        {
          name: "@IDINDICADORES",
          value: idindicadores,
          type: "string"
        },
        {
          name: "@FECHA_INICIO",
          value: fecha_ini,
          type: "int"
        },
        {
          name: "@FECHA_FIN",
          value: fecha_fin,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE, request);
  }
};
